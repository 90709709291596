import { useId } from "react";
import {
  FormDataList,
  FormInput,
  FormPhoneInput,
  FormTextArea,
  RegularButton,
  FormRadio,
  FormSelect,
} from "infrastructure/components";
import {
  consumerIdealAge,
  countries,
  formErrors,
  formValues,
  staffAmount,
} from "./utils";
import { useForm } from "./hooks";

import { BeatLoader } from "react-spinners";
import * as S from "./BriefForm.styled";
import moment from "moment";

const BriefForm = () => {
  const inputId = useId();

  const {
    values,
    errors,
    disableSubmit,
    isValidating,
    isLoading,
    handleSubmit,
    phoneNumberHandleChange,
    inputHandleChange,
    textAreaHandleChange,
    selectHandleChange,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.FieldSet>
        <S.FieldLegend>Información básica de la Compañía</S.FieldLegend>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-companyNameInput`}
            name="companyName"
            type="text"
            placeholder="Compañía"
            onChange={inputHandleChange}
            value={values.companyName}
            required
            formInputProps={{
              label: "Nombre de la compañía *",
              errorMessage: errors.companyName,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-dateFoundedInput`}
            name="companyStartDate"
            max={moment().subtract(1, "days").format("YYYY-MM-DD")}
            type="date"
            onChange={inputHandleChange}
            value={values.companyStartDate}
            formInputProps={{
              label: "Fecha en que fue fundada",
              errorMessage: errors.companyStartDate,
              withCheck: false,
              isValidating,
            }}
          />
        </S.InputsContainer>

        <FormInput
          id={`${inputId}-emailInput`}
          name="companyEmail"
          type="email"
          placeholder="email@email.com"
          onChange={inputHandleChange}
          value={values.companyEmail}
          required
          formInputProps={{
            label: "Correo *",
            errorMessage: errors.companyEmail,
            withCheck: true,
            isValidating,
          }}
        />
        <S.InputsContainer>
          <FormPhoneInput
            id={`${inputId}-phoneInput`}
            name="phoneNumber"
            placeholder="1 (234) 567-8910"
            formInputProps={{
              label: "Número de teléfono *",
              errorMessage: errors.phoneNumber,
              withCheck: true,
              onChange: phoneNumberHandleChange,
              value: values.phoneNumber || "",
              isValidating,
            }}
          />
          <FormPhoneInput
            id={`${inputId}-phoneWhatsappBussinessInput`}
            name="phoneWhatsappBussiness"
            placeholder="1 (234) 567-8910"
            formInputProps={{
              label: "Whatsapp Business *",
              errorMessage: errors.phoneWhatsappBussiness,
              withCheck: true,
              onChange: phoneNumberHandleChange,
              value: values.phoneWhatsappBussiness || "",
              isValidating,
            }}
          />
        </S.InputsContainer>

        <FormInput
          id={`${inputId}-websiteInput`}
          name="website"
          onChange={inputHandleChange}
          value={values.website}
          // required
          formInputProps={{
            errorMessage: errors.website,
            label: "Website / Sitio Web (Solo si posee):",
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.FieldSet>
        <S.FieldLegend>Información de ubicación</S.FieldLegend>
        <FormInput
          id={`${inputId}-address1Input`}
          name="address1"
          type="text"
          placeholder="Indica sector/calle/edificio"
          onChange={inputHandleChange}
          value={values.address1}
          required
          formInputProps={{
            label: "Dirección 1 *",
            errorMessage: errors.address1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-address2Input`}
          name="address2"
          type="text"
          placeholder="Indica sector/calle/edificio"
          onChange={inputHandleChange}
          value={values.address2}
          formInputProps={{
            label: "Dirección 2",
            errorMessage: errors.address2,
            withCheck: true,
            isValidating,
          }}
        />
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-cityInput`}
            name="city"
            type="text"
            placeholder="Barcelona"
            onChange={inputHandleChange}
            value={values.city}
            required
            formInputProps={{
              label: "Ciudad *",
              errorMessage: errors.city,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-stateInput`}
            name="state"
            type="text"
            placeholder="Anzoátegui"
            onChange={inputHandleChange}
            value={values.state}
            required
            formInputProps={{
              label: "Estado *",
              errorMessage: errors.state,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-zipCodeInput`}
            name="zipCode"
            type="text"
            placeholder="0230"
            onChange={inputHandleChange}
            value={values.zipCode}
            formInputProps={{
              label: "Código De Area",
              errorMessage: errors.zipCode,
              withCheck: false,
              isValidating,
            }}
          />

          <FormDataList
            id={`${inputId}-countryInput`}
            name="country"
            type="text"
            placeholder="Venezuela (VE)"
            onChange={inputHandleChange}
            value={values.country}
            required
            formInputProps={{
              options: countries,
              label: "País *",
              errorMessage: errors.country,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>

      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>
          Persona de contacto directo encargado/a de Social Media:
        </S.FieldLegend>
        <FormInput
          id={`${inputId}-directContactPersonFullnameInput`}
          name="directContactPersonFullname"
          onChange={inputHandleChange}
          value={values.directContactPersonFullname}
          required
          formInputProps={{
            errorMessage: errors.directContactPersonFullname,
            label: "Nombre y Apellido: *",
            withCheck: true,
            isValidating,
          }}
        />

        <FormPhoneInput
          id={`${inputId}-directContactPersonPhoneInput`}
          name="directContactPersonPhone"
          placeholder="1 (234) 567-8910"
          formInputProps={{
            label: "Número de teléfono *",
            errorMessage: errors.directContactPersonPhone,
            withCheck: true,
            onChange: phoneNumberHandleChange,
            value: values.directContactPersonPhone || "",
            isValidating,
          }}
        />
      </S.FieldSet>

      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Información detallada de la empresa</S.FieldLegend>
        <FormSelect
          id={`${inputId}-staffAmountInput`}
          name="staffAmount"
          onChange={selectHandleChange}
          value={values.staffAmount}
          required
          formSelectProps={{
            errorMessage: errors.staffAmount,
            label: "Cuantas personas trabajan en la empresa *",
            options: staffAmount,
            withCheck: false,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-briefCompanyHistoryInput`}
          name="briefCompanyHistory"
          onChange={textAreaHandleChange}
          value={values.briefCompanyHistory}
          required
          formTextAreaProps={{
            errorMessage: errors.briefCompanyHistory,
            label: "Breve descripción de la empresa *",
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-missionInput`}
          name="missionVision"
          onChange={textAreaHandleChange}
          value={values.missionVision}
          required
          formTextAreaProps={{
            errorMessage: errors.missionVision,
            label: "Misión & visión *",
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-industrySectorInput`}
          name="industrySector"
          onChange={textAreaHandleChange}
          value={values.industrySector}
          required
          formTextAreaProps={{
            errorMessage: errors.industrySector,
            label: "En qué tipo de industria en específico trabaja? *",
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-horarioLaboralInput`}
          name="horarioLaboral"
          onChange={textAreaHandleChange}
          value={values.horarioLaboral}
          required
          formTextAreaProps={{
            errorMessage: errors.horarioLaboral,
            label: "Horario de Trabajo (Ejemplo: LUNES A SÁBADO 8AM – 5PM) *",
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Información de la marca</S.FieldLegend>
        <FormTextArea
          id={`${inputId}-productOrServiceInput`}
          name="productOrService"
          onChange={textAreaHandleChange}
          value={values.productOrService}
          required
          formTextAreaProps={{
            errorMessage: errors.productOrService,
            label:
              "Qué productos y servicios ofrece su compañía actualmente? *",
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-productOrServicePricesInput`}
          name="productOrServicePrices"
          onChange={textAreaHandleChange}
          value={values.productOrServicePrices}
          required
          formTextAreaProps={{
            errorMessage: errors.productOrServicePrices,
            label: "Precio de productos o servicios: *",
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-fiverWordsDescribeBrandInput`}
          name="fiverWordsDescribeBrand"
          onChange={textAreaHandleChange}
          value={values.fiverWordsDescribeBrand}
          required
          formTextAreaProps={{
            errorMessage: errors.fiverWordsDescribeBrand,
            label: "Nombrar cuatro (4) palabras que definan la Marca *",
            withCheck: true,
            isValidating,
          }}
        />
        <S.Label>Nombre algunas competencias directas</S.Label>
        <FormInput
          id={`${inputId}-competitors1Input`}
          name="competitors1"
          type="text"
          placeholder="Competidor 1 *"
          onChange={inputHandleChange}
          value={values.competitors1}
          formInputProps={{
            errorMessage: errors.competitors1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors2Input`}
          name="competitors2"
          type="text"
          placeholder="Competidor 2"
          onChange={inputHandleChange}
          value={values.competitors2}
          formInputProps={{
            errorMessage: errors.competitors2,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-competitors3Input`}
          name="competitors3"
          type="text"
          placeholder="Competidor 3"
          onChange={inputHandleChange}
          value={values.competitors3}
          formInputProps={{
            errorMessage: errors.competitors3,
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-diferenceBetwenYourBrandAndCompetitorsInput`}
          name="diferenceBetwenYourBrandAndCompetitors"
          onChange={textAreaHandleChange}
          value={values.diferenceBetwenYourBrandAndCompetitors}
          required
          formTextAreaProps={{
            label: "En que se diferencia tu marca de la competencia: *",
            errorMessage: errors.diferenceBetwenYourBrandAndCompetitors,
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>

      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Información del consumidor</S.FieldLegend>
        <S.Label>¿Género del consumidor ideal?: *</S.Label>

        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderM`,
              label: "Masculino",
              name: "idealConsumerGender",
              radioValue: "Masculino",
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderF`,
              label: "Femenino",
              name: "idealConsumerGender",
              radioValue: "Femenino",
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderA`,
              label: "Ambos",
              name: "idealConsumerGender",
              radioValue: "Ambos",
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerGender}</S.Error>
        </S.RadioCont>

        <S.Label>
          ¿De que genero se compone principalmente el consumidor?: *
        </S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderM`,
              label: "Masculino",
              name: "actualPrincipalConsumerGender",
              radioValue: "Masculino",
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderF`,
              label: "Femenino",
              name: "actualPrincipalConsumerGender",
              radioValue: "Femenino",
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderA`,
              label: "Ambos",
              name: "actualPrincipalConsumerGender",
              radioValue: "Ambos",
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderO`,
              label: "Otros",
              name: "actualPrincipalConsumerGender",
              radioValue: "Otros",
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.actualPrincipalConsumerGender}</S.Error>
        </S.RadioCont>

        <FormSelect
          id={`${inputId}-idealConsumerAge`}
          name="idealConsumerAge"
          onChange={selectHandleChange}
          value={values.idealConsumerAge}
          required
          formSelectProps={{
            errorMessage: errors.idealConsumerAge,
            label: "Edad del consumidor ideal: *",
            options: consumerIdealAge,
            withCheck: false,
            isValidating,
          }}
        />

        <S.Label>Ubicación del consumidor ideal: *</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationNacional`}
              name="idealConsumerLocationisNacional"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNacional}
              formInputProps={{
                label: "Nacional",
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaLatina`}
              name="idealConsumerLocationisAmericaLatina"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaLatina}
              formInputProps={{
                label: "América Latina",
                withCheck: false,
                isValidating,
              }}
            />

            <FormInput
              id={`${inputId}-idealConsumerLocationisNorteAmerica`}
              name="idealConsumerLocationisNorteAmerica"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNorteAmerica}
              formInputProps={{
                label: "Norteamérica",
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYEuropa`}
              name="idealConsumerLocationisAmericaYEuropa"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYEuropa}
              formInputProps={{
                label: "América Y Europa",
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYAsia`}
              name="idealConsumerLocationisAmericaYAsia"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYAsia}
              formInputProps={{
                label: "América Y Asia",
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisInternacional`}
              name="idealConsumerLocationisInternacional"
              type="checkbox"
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisInternacional}
              formInputProps={{
                label: "Internacional",
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.Error $isCheckBox>{errors.idealConsumerLocation}</S.Error>
        </S.CheckBoxCont>

        <S.Label>Nivel socieconómico del consumidor ideal: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlA`,
              label: "Clase alta",
              name: "idealConsumerSocioEconomicLvl",
              radioValue: "Clase alta",
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlM`,
              label: "Clase media",
              name: "idealConsumerSocioEconomicLvl",
              radioValue: "Clase media",
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlB`,
              label: "Clase baja",
              name: "idealConsumerSocioEconomicLvl",
              radioValue: "Clase baja",
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />

          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlT`,
              label: "Todas Las Anteriores",
              name: "idealConsumerSocioEconomicLvl",
              radioValue: "Todas (baja, media, alta)",
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerSocioEconomicLvl}</S.Error>
        </S.RadioCont>
      </S.FieldSet>

      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Información detallada de la marca</S.FieldLegend>
        <FormInput
          id={`${inputId}-sloganInput`}
          name="slogan"
          type="text"
          onChange={inputHandleChange}
          value={values.slogan}
          required
          formInputProps={{
            label: "Slogan o Tagline de la compañía: *",
            errorMessage: errors.slogan,
            withCheck: true,
            isValidating,
          }}
        />

        <S.Label style={{ marginBottom: "5px" }}>
          Nombrar 3-4 Highlights de historias esenciales: *
          <br />
          <span>(Ej. CONTACTO, UBICACIÓN, CLASES, PROMOCIONES)</span>
        </S.Label>
        <FormTextArea
          id={`${inputId}-highlightsHistoryEssentialsInput`}
          style={{ paddingTop: "0px", marginTop: "0px" }}
          name="highlightsHistoryEssentials"
          onChange={textAreaHandleChange}
          value={values.highlightsHistoryEssentials}
          required
          formTextAreaProps={{
            errorMessage: errors.highlightsHistoryEssentials,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-productBenefitsInput`}
          name="productBenefits"
          onChange={textAreaHandleChange}
          value={values.productBenefits}
          required
          formTextAreaProps={{
            label: "Beneficios de Productos: *",
            errorMessage: errors.productBenefits,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-healthCareOrWarningsInput`}
          name="healthCareOrWarnings"
          onChange={textAreaHandleChange}
          value={values.healthCareOrWarnings}
          // required
          formTextAreaProps={{
            label: "Cuidados o Advertencias de Salud (Opcional):",
            errorMessage: errors.healthCareOrWarnings,
            withCheck: true,
            isValidating,
          }}
        />
        <S.Label>
          ¿Posee manual de identidad? *
          <br />
          <span>
            Ej. (Logotipo, tipografía, Paleta de colores, Tono y Voz de marca)
          </span>
        </S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-identityHandbookSi`,
              label: "SÍ",
              name: "identityHandbook",
              radioValue: "yes",
              value: values.identityHandbook,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-identityHandbookNo`,
              label: "NO",
              name: "identityHandbook",
              radioValue: "no",
              value: values.identityHandbook,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.identityHandbook}</S.Error>
        </S.RadioCont>
        <S.Label>¿Su companía ofrece descuentos o promociones? *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-offerDiscountsSi`,
              label: "SÍ",
              name: "offerDiscounts",
              radioValue: "yes",
              value: values.offerDiscounts,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-offerDiscountsNo`,
              label: "NO",
              name: "offerDiscounts",
              radioValue: "no",
              value: values.offerDiscounts,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.offerDiscounts}</S.Error>
        </S.RadioCont>
        {values.offerDiscounts === "yes" ? (
          <>
            <S.Label>
              ¿Le gustaría dar a conocer esos descuentos o promociones en las
              redes sociales (Instagram)? *
            </S.Label>
            <S.RadioCont>
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-promoteDiscountsSi`,
                  label: "SÍ",
                  name: "promoteDiscounts",
                  radioValue: "yes",
                  value: values.promoteDiscounts,
                  handleChange: inputHandleChange,
                }}
              />
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-promoteDiscountsNo`,
                  label: "NO",
                  name: "promoteDiscounts",
                  radioValue: "no",
                  value: values.promoteDiscounts,
                  handleChange: inputHandleChange,
                }}
              />
              <S.Error>{errors.promoteDiscounts}</S.Error>
            </S.RadioCont>
          </>
        ) : null}
        <S.Label>
          ¿Se esta haciendo uso de Ads (Publicidad) para Instagram? *
        </S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-usingAdsForInstagramSi`,
              label: "Sí",
              name: "usingAdsForInstagram",
              radioValue: "yes",
              value: values.usingAdsForInstagram,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-usingAdsForInstagramNo`,
              label: "No",
              name: "usingAdsForInstagram",
              radioValue: "no",
              value: values.usingAdsForInstagram,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.usingAdsForInstagram}</S.Error>
        </S.RadioCont>
        {values.usingAdsForInstagram === "no" ? (
          <>
            <S.Label>
              ¿Les gustaría Impulsar su marca con Ads para Instagram?
            </S.Label>
            <S.RadioCont>
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-boostInstagramWithAdsSi`,
                  label: "Si",
                  name: "boostInstagramWithAds",
                  radioValue: "yes",
                  value: values.boostInstagramWithAds,
                  handleChange: inputHandleChange,
                }}
              />
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-boostInstagramWithAdsNo`,
                  label: "No",
                  name: "boostInstagramWithAds",
                  radioValue: "no",
                  value: values.boostInstagramWithAds,
                  handleChange: inputHandleChange,
                }}
              />
              <S.Error>{errors.boostInstagramWithAds}</S.Error>
            </S.RadioCont>
          </>
        ) : null}
        {values.usingAdsForInstagram === "no" &&
        values.boostInstagramWithAds === "yes" ? (
          <FormInput
            id={`${inputId}-boostInstagramAdsAmountInput`}
            name="boostInstagramAdsAmount"
            placeholder="400"
            onChange={inputHandleChange}
            value={values.boostInstagramAdsAmount}
            required
            formInputProps={{
              errorMessage: errors.boostInstagramAdsAmount,
              label: "$ Presupuesto mensual para Ads de Instagram: *",
              withCheck: true,
              isValidating,
            }}
          />
        ) : null}
        <S.Label style={{ paddingBottom: "0px" }}>
          ¿Posee cuenta bancaria en los estados unidos de america para Ads
          Manager de Facebook/Instagram? *
          <br />
          <span>(BANK OF AMERICA, CHASE JP MORGAN, ETC)</span>
        </S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-hasBankAccountInUSASi`,
              label: "Sí",
              name: "hasBankAccountInUSA",
              radioValue: "yes",
              value: values.hasBankAccountInUSA,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-hasBankAccountInUSANo`,
              label: "No",
              name: "hasBankAccountInUSA",
              radioValue: "no",
              value: values.hasBankAccountInUSA,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.hasBankAccountInUSA}</S.Error>
        </S.RadioCont>
        {values.hasBankAccountInUSA === "yes" ? (
          <>
            <S.Label>
              ¿La cuenta ya está configurada para los cobros de facebook?
            </S.Label>
            <S.RadioCont>
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-bankAccountConfiguredSi`,
                  label: "Sí",
                  name: "isBankAccountConfigured",
                  radioValue: "yes",
                  value: values.isBankAccountConfigured,
                  handleChange: inputHandleChange,
                }}
              />
              <FormRadio
                formRadioProps={{
                  id: `${inputId}-bankAccountConfiguredNo`,
                  label: "No",
                  name: "isBankAccountConfigured",
                  radioValue: "no",
                  value: values.isBankAccountConfigured,
                  handleChange: inputHandleChange,
                }}
              />
              <S.Error>{errors.isBankAccountConfigured}</S.Error>
            </S.RadioCont>
          </>
        ) : null}
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Credenciales de Acceso</S.FieldLegend>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-userEmailInput`}
            name="userEmail"
            onChange={inputHandleChange}
            value={values.userEmail}
            required
            formInputProps={{
              errorMessage: errors.userEmail,
              label: "Usuario o Email: *",
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-userPassInput`}
            name="userPass"
            type="password"
            onChange={inputHandleChange}
            value={values.userPass}
            required
            formInputProps={{
              errorMessage: errors.userPass,
              label: "Contraseña: *",
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>

        <S.Label
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            fontWeight: "600",
          }}
        >
          Instagram *
        </S.Label>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-instagramUserInput`}
            name="instagramUser"
            onChange={inputHandleChange}
            value={values.instagramUser}
            required
            formInputProps={{
              errorMessage: errors.instagramUser,
              label: "Usuario o Email:",
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-instagramPassInput`}
            name="instagramPass"
            type="password"
            onChange={inputHandleChange}
            value={values.instagramPass}
            required
            formInputProps={{
              errorMessage: errors.instagramPass,
              label: "Contraseña:",
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>

        <S.Label
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            fontWeight: "600",
          }}
        >
          Facebook *
        </S.Label>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-facebookUserInput`}
            name="facebookUser"
            onChange={inputHandleChange}
            value={values.facebookUser}
            required
            formInputProps={{
              errorMessage: errors.facebookUser,
              label: "Usuario o Email:",
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-facebookPassInput`}
            name="facebookPass"
            type="password"
            onChange={inputHandleChange}
            value={values.facebookPass}
            required
            formInputProps={{
              errorMessage: errors.facebookPass,
              label: "Contraseña:",
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <FormTextArea
          id={`${inputId}-aditionalInfoInput`}
          name="aditionalInfo"
          placeholder="TENEMOS LA TIENDA DE INSTAGRAM SHOPPING ACTIVA Y ETIQUETAMOS PRODUCTOS EN HISTORIAS Y POST "
          onChange={textAreaHandleChange}
          value={values.aditionalInfo}
          formTextAreaProps={{
            errorMessage: errors.aditionalInfo,
            label: "Información adicional sobre la marca o empresa:",
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>

      {isLoading ? (
        <S.SpinnerCont>
          <BeatLoader color="#ffffff50" margin={3} speedMultiplier={0.6} />
        </S.SpinnerCont>
      ) : (
        <RegularButton
          regularButtonProps={{ color: "orange", text: "ENVIAR" }}
          disabled={disableSubmit}
          type="submit"
        />
      )}
    </S.Form>
  );
};

export default BriefForm;
