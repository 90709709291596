import { isValidPhoneNumber } from "libphonenumber-js";
import { FormErrorsProps, FormValueProps } from "../interfaces/interfaces";
import countries from "./countries";

const validation = ({
  //1
  companyName,
  //companyStartDate,
  companyEmail,
  phoneNumber,
  phoneWhatsappBussiness,

  //2
  address1,
  address2,
  city,
  state,
  zipCode,
  country,

  //3
  staffAmount,
  briefCompanyHistory,
  missionVision,
  industrySector,
  horarioLaboral,
  website,

  //4
  //Informacion de la marca
  productOrService,
  productOrServicePrices,
  fiverWordsDescribeBrand,
  competitors1,
  //competitors2,
  //competitors3,
  diferenceBetwenYourBrandAndCompetitors,

  // Social Media
  directContactPersonFullname,
  directContactPersonPhone,

  offerDiscounts,
  promoteDiscounts,
  usingAdsForInstagram,
  boostInstagramWithAds,
  boostInstagramAdsAmount,
  hasBankAccountInUSA,
  isBankAccountConfigured,
  //

  // Seccion de credenciales
  userEmail,
  userPass,
  instagramUser,
  instagramPass,
  facebookUser,
  facebookPass,

  //5
  //consumer
  idealConsumerGender,
  actualPrincipalConsumerGender,
  idealConsumerAge,
  idealConsumerSocioEconomicLvl,
  //
  idealConsumerLocationisNacional,
  idealConsumerLocationisAmericaLatina,
  idealConsumerLocationisNorteAmerica,
  idealConsumerLocationisAmericaYEuropa,
  idealConsumerLocationisAmericaYAsia,
  idealConsumerLocationisInternacional,
  //6

  //Informacion Detallada De La Marca
  highlightsHistoryEssentials,
  // healthCareOrWarnings,
  productBenefits,
  slogan,
  identityHandbook,

  aditionalInfo,
}: FormValueProps) => {
  const errors: FormErrorsProps = {
    //1
    companyName: "",
    companyStartDate: "",
    companyEmail: "",
    phoneNumber: "",
    phoneWhatsappBussiness: "",

    //2
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",

    //3
    staffAmount: "",
    briefCompanyHistory: "",
    missionVision: "",
    industrySector: "",
    horarioLaboral: "",
    website: "",

    //4
    //Informacion de la marca
    productOrService: "",
    productOrServicePrices: "",
    fiverWordsDescribeBrand: "",
    competitors1: "",
    competitors2: "",
    competitors3: "",
    diferenceBetwenYourBrandAndCompetitors: "",

    // Social Media
    directContactPersonFullname: "",
    directContactPersonPhone: "",

    offerDiscounts: "",
    promoteDiscounts: "",
    usingAdsForInstagram: "",
    boostInstagramWithAds: "",
    boostInstagramAdsAmount: "",
    hasBankAccountInUSA: "",
    isBankAccountConfigured: "",
    //

    // Seccion de credenciales
    userEmail: "",
    userPass: "",
    instagramUser: "",
    instagramPass: "",
    facebookUser: "",
    facebookPass: "",

    //5
    //consumer
    idealConsumerGender: "",
    actualPrincipalConsumerGender: "",
    idealConsumerAge: "",
    idealConsumerLocation: "",
    idealConsumerSocioEconomicLvl: "",
    //6

    //Informacion Detallada De La Marca
    highlightsHistoryEssentials: "",
    productBenefits: "",
    healthCareOrWarnings: "",
    slogan: "",
    identityHandbook: "",

    aditionalInfo: "",
  };

  //Validation for name
  if (!companyName.trim()) {
    errors.companyName = "Este campo es requerido";
  } else if (companyName.trim() && companyName.trim().length < 3) {
    errors.companyName = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.companyName = "";
  }

  //Validation for email
  if (!companyEmail.trim()) {
    errors.companyEmail = "Este campo es requerido";
  } else if (
    companyEmail.trim() &&
    !companyEmail
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.companyEmail = "Use un correo correcto";
  } else {
    errors.companyEmail = "";
  }

  //phoneNumber
  if (!phoneNumber.trim()) {
    errors.phoneNumber = "Este campo es requerido";
  } else if (phoneNumber.trim() && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = "Use un número deteléfono correcto";
  } else {
    errors.phoneNumber = "";
  }

  // phoneWhatsappBussiness
  if (!phoneWhatsappBussiness.trim()) {
    errors.phoneWhatsappBussiness = "Este campo es requerido";
  } else if (
    phoneWhatsappBussiness.trim() &&
    !isValidPhoneNumber(phoneWhatsappBussiness)
  ) {
    errors.phoneWhatsappBussiness = "Use un número deteléfono correcto";
  } else {
    errors.phoneWhatsappBussiness = "";
  }

  //address 1
  if (!address1.trim()) {
    errors.address1 = "Este campo es requerido";
  } else if (address1.trim() && address1.trim().length < 3) {
    errors.address1 = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.address1 = "";
  }

  //address 2
  if (address2.trim() && address2.trim().length < 3) {
    errors.address2 = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.address2 = "";
  }

  //city
  if (!city.trim()) {
    errors.city = "Este campo es requerido";
  } else if (city.trim() && city.trim().length < 3) {
    errors.city = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.city = "";
  }

  //state
  if (!state.trim()) {
    errors.state = "Este campo es requerido";
  } else if (state.trim() && state.trim().length < 3) {
    errors.state = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.state = "";
  }

  //zipcode
  if (zipCode.trim() && !/^[0-9\- ]*$/.test(zipCode.trim())) {
    errors.zipCode = "Solo número y guiones son permitidos";
  } else {
    errors.zipCode = "";
  }

  //country
  if (!country.trim()) {
    errors.country = "Este campo es requerido";
  } else if (
    !!!countries.find(
      (item) =>
        item.name === country.replace(/\b[a-z]/g, (char) => char.toUpperCase())
    )
  ) {
    errors.country = "Elija un nombre de pais valido";
  } else {
    errors.country = "";
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = "Este campo es requerido";
  } else {
    errors.staffAmount = "";
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = "Este campo es requerido";
  } else {
    errors.staffAmount = "";
  }

  //company history
  if (!briefCompanyHistory.trim()) {
    errors.briefCompanyHistory = "Este campo es requerido";
  } else {
    errors.briefCompanyHistory = "";
  }

  //mission vision
  if (!missionVision.trim()) {
    errors.missionVision = "Este campo es requerido";
  } else {
    errors.missionVision = "";
  }

  //industry sector
  if (!industrySector.trim()) {
    errors.industrySector = "Este campo es requerido";
  } else {
    errors.industrySector = "";
  }

  // horario
  if (!horarioLaboral.trim()) {
    errors.horarioLaboral = "Este campo es requerido";
  } else {
    errors.horarioLaboral = "";
  }

  // if (!website.trim()) {
  //   errors.website = "Este campo es requerido";
  // } else {
  //   errors.website = "";
  // }

  //product or service
  if (!productOrService.trim()) {
    errors.productOrService = "Este campo es requerido";
  } else {
    errors.productOrService = "";
  }

  //product or service
  if (!productOrServicePrices.trim()) {
    errors.productOrServicePrices = "Este campo es requerido";
  } else {
    errors.productOrServicePrices = "";
  }

  //fiverWordsDescribeBrand
  if (!fiverWordsDescribeBrand.trim()) {
    errors.fiverWordsDescribeBrand = "Este campo es requerido";
  } else {
    errors.fiverWordsDescribeBrand = "";
  }

  //competitors1
  if (!competitors1.trim()) {
    errors.competitors1 = "Este campo es requerido";
  } else {
    errors.competitors1 = "";
  }

  //diferenceBetwenYourBrandAndCompetitors
  if (!diferenceBetwenYourBrandAndCompetitors.trim()) {
    errors.diferenceBetwenYourBrandAndCompetitors = "Este campo es requerido";
  } else {
    errors.diferenceBetwenYourBrandAndCompetitors = "";
  }

  // Social Media
  if (!directContactPersonFullname.trim()) {
    errors.directContactPersonFullname = "Este campo es requerido";
  } else {
    errors.directContactPersonFullname = "";
  }

  if (!directContactPersonPhone.trim()) {
    errors.directContactPersonPhone = "Este campo es requerido";
  } else if (
    directContactPersonPhone.trim() &&
    !isValidPhoneNumber(directContactPersonPhone)
  ) {
    errors.directContactPersonPhone = "Use un número deteléfono correcto";
  } else {
    errors.directContactPersonPhone = "";
  }

  if (!offerDiscounts.trim()) {
    errors.offerDiscounts = "Este campo es requerido";
  } else {
    errors.offerDiscounts = "";
    if (!promoteDiscounts.trim()) {
      errors.promoteDiscounts = "Este campo es requerido";
    } else {
      errors.promoteDiscounts = "";
    }
  }

  if (!usingAdsForInstagram.trim()) {
    errors.usingAdsForInstagram = "Este campo es requerido";
  } else {
    errors.usingAdsForInstagram = "";
    if (usingAdsForInstagram === "no") {
      if (!boostInstagramWithAds.trim()) {
        errors.boostInstagramWithAds = "Este campo es requerido";
      } else {
        errors.boostInstagramWithAds = "";
        if (boostInstagramWithAds === "yes") {
          if (!boostInstagramAdsAmount.trim()) {
            errors.boostInstagramAdsAmount = "Este campo es requerido";
          } else {
            errors.boostInstagramAdsAmount = "";
          }
        }
      }
    }
  }

  // Credenciales
  if (!userEmail.trim()) {
    errors.userEmail = "Este campo es requerido";
  } else {
    errors.userEmail = "";
  }
  if (!userPass.trim()) {
    errors.userPass = "Este campo es requerido";
  } else {
    errors.userPass = "";
  }
  if (!instagramUser.trim()) {
    errors.instagramUser = "Este campo es requerido";
  } else {
    errors.instagramUser = "";
  }
  if (!instagramPass.trim()) {
    errors.instagramPass = "Este campo es requerido";
  } else {
    errors.instagramPass = "";
  }
  if (!facebookUser.trim()) {
    errors.facebookUser = "Este campo es requerido";
  } else {
    errors.facebookUser = "";
  }
  if (!facebookPass.trim()) {
    errors.facebookPass = "Este campo es requerido";
  } else {
    errors.facebookPass = "";
  }

  if (!hasBankAccountInUSA.trim()) {
    errors.hasBankAccountInUSA = "Este campo es requerido";
  } else {
    errors.hasBankAccountInUSA = "";
    if (hasBankAccountInUSA === "yes") {
      if (!isBankAccountConfigured.trim()) {
        errors.isBankAccountConfigured = "Este campo es requerido";
      } else {
        errors.isBankAccountConfigured = "";
      }
    }
  }

  if (!idealConsumerGender.trim()) {
    errors.idealConsumerGender = "Este campo es requerido";
  } else {
    errors.idealConsumerGender = "";
  }

  if (!actualPrincipalConsumerGender.trim()) {
    errors.actualPrincipalConsumerGender = "Este campo es requerido";
  } else {
    errors.actualPrincipalConsumerGender = "";
  }

  if (!idealConsumerAge.trim()) {
    errors.idealConsumerAge = "Este campo es requerido";
  } else {
    errors.idealConsumerAge = "";
  }

  const idealConsumerLocation = {
    idealConsumerLocationisNacional,
    idealConsumerLocationisAmericaLatina,
    idealConsumerLocationisNorteAmerica,
    idealConsumerLocationisAmericaYEuropa,
    idealConsumerLocationisAmericaYAsia,
    idealConsumerLocationisInternacional,
  };

  const noOneIsPick = Object.values(idealConsumerLocation).every(
    (location) => location === false
  );

  if (noOneIsPick) {
    errors.idealConsumerLocation = "Este campo es requerido";
  } else {
    errors.idealConsumerLocation = "";
  }

  if (!idealConsumerSocioEconomicLvl.trim()) {
    errors.idealConsumerSocioEconomicLvl = "Este campo es requerido";
  } else {
    errors.idealConsumerSocioEconomicLvl = "";
  }

  if (!highlightsHistoryEssentials.trim()) {
    errors.highlightsHistoryEssentials = "Este campo es requerido";
  } else {
    errors.highlightsHistoryEssentials = "";
  }

  if (!productBenefits.trim()) {
    errors.productBenefits = "Este campo es requerido";
  } else {
    errors.productBenefits = "";
  }

  // if (!healthCareOrWarnings.trim()) {
  //   errors.healthCareOrWarnings = "Este campo es requerido";
  // } else {
  //   errors.healthCareOrWarnings = "";
  // }

  if (!slogan.trim()) {
    errors.slogan = "Este campo es requerido";
  } else if (slogan.trim() && slogan.trim().length < 3) {
    errors.slogan = "Mínimo debe escribir 3 caracteres";
  } else {
    errors.slogan = "";
  }

  if (!identityHandbook.trim()) {
    errors.identityHandbook = "Este campo es requerido";
  } else {
    errors.identityHandbook = "";
  }

  if (aditionalInfo.trim() && aditionalInfo.trim().length < 6) {
    errors.aditionalInfo = "Mínimo debe escribir 6 caracteres";
  } else {
    errors.aditionalInfo = "";
  }
  return errors;
};

export default validation;
