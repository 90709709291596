import { FormValueProps, FormErrorsProps } from "../interfaces";

export const formValues: FormValueProps = {
  //1
  // companyName: {
  //   label: "Compania",
  //   value: "",
  // },
  companyName: "",
  companyStartDate: "",
  companyEmail: "",
  phoneNumber: "",
  phoneWhatsappBussiness: "",

  //2
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",

  //3
  staffAmount: "",
  briefCompanyHistory: "",
  missionVision: "",
  industrySector: "",
  horarioLaboral: "",
  website: "",

  //4
  //Informacion de la marca
  productOrService: "",
  productOrServicePrices: "",
  fiverWordsDescribeBrand: "",
  competitors1: "",
  competitors2: "",
  competitors3: "",
  diferenceBetwenYourBrandAndCompetitors: "",

  // Social Media
  directContactPersonFullname: "",
  directContactPersonPhone: "",

  offerDiscounts: "no",
  promoteDiscounts: "yes",
  usingAdsForInstagram: "no",
  boostInstagramWithAds: "no",
  boostInstagramAdsAmount: "",
  hasBankAccountInUSA: "no",
  isBankAccountConfigured: "no",
  //

  // Credenciales
  userEmail: "",
  userPass: "",
  instagramUser: "",
  instagramPass: "",
  facebookUser: "",
  facebookPass: "",

  //5
  //consumer
  idealConsumerGender: "",
  actualPrincipalConsumerGender: "",
  idealConsumerAge: "",
  idealConsumerSocioEconomicLvl: "",
  //
  idealConsumerLocationisNacional: false,
  idealConsumerLocationisAmericaLatina: false,
  idealConsumerLocationisNorteAmerica: false,
  idealConsumerLocationisAmericaYEuropa: false,
  idealConsumerLocationisAmericaYAsia: false,
  idealConsumerLocationisInternacional: false,
  //6

  //Informacion Detallada De La Marca
  highlightsHistoryEssentials: "",
  productBenefits: "",
  healthCareOrWarnings: "",
  slogan: "",
  identityHandbook: "no",

  aditionalInfo: "",
};

export const formErrors: FormErrorsProps = {
  //1
  companyName: "",
  companyStartDate: "",
  companyEmail: "",
  phoneNumber: "",
  phoneWhatsappBussiness: "",

  //2
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",

  //3
  staffAmount: "",
  briefCompanyHistory: "",
  missionVision: "",
  industrySector: "",
  horarioLaboral: "",
  website: "",

  //4
  //Informacion de la marca
  productOrService: "",
  productOrServicePrices: "",
  fiverWordsDescribeBrand: "",
  competitors1: "",
  competitors2: "",
  competitors3: "",
  diferenceBetwenYourBrandAndCompetitors: "",

  // Social Media
  directContactPersonFullname: "",
  directContactPersonPhone: "",

  offerDiscounts: "",
  promoteDiscounts: "",
  usingAdsForInstagram: "",
  boostInstagramWithAds: "",
  boostInstagramAdsAmount: "",
  hasBankAccountInUSA: "",
  isBankAccountConfigured: "",
  //

  // Credenciales
  userEmail: "",
  userPass: "",
  instagramUser: "",
  instagramPass: "",
  facebookUser: "",
  facebookPass: "",

  //5
  //consumer
  idealConsumerGender: "",
  actualPrincipalConsumerGender: "",
  idealConsumerAge: "",
  idealConsumerLocation: "",
  idealConsumerSocioEconomicLvl: "",
  //6

  //Informacion Detallada De La Marca
  highlightsHistoryEssentials: "",
  productBenefits: "",
  healthCareOrWarnings: "",
  slogan: "",
  identityHandbook: "",

  aditionalInfo: "",
};
