import styled, { css } from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20px;
  border-radius: 10px;
  width: min(100%, 620px);
  z-index: 1;
  margin: 0 auto;

  & button {
    margin: auto 0 auto auto;
  }

  & button:disabled {
    cursor: not-allowed;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 900px) {
    margin: 0;
  }
`;

export const SpinnerCont = styled.div`
  margin: 0 0 0 auto;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 49%;
    }
  }
`;

export const FieldSet = styled.fieldset`
  width: 100%;
  border: none;
`;

export const FieldLegend = styled.legend`
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 22px;
`;

export const CheckBoxCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--color-soft-white);
  & span {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const Division = styled.hr`
  border: 0.5px solid var(--color-orange);
  margin: 30px auto 30px auto;

  width: 80%;
`;

export const LinkTo = styled.a``;

export const RadioCont = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

interface ErrorProps {
  $isCheckBox?: boolean;
}

export const Error = styled.span<ErrorProps>`
  text-align: left;
  font-size: 16px;
  color: #ef4848;
  display: block;
  position: absolute;
  bottom: 5px;

  ${({ $isCheckBox }) =>
    $isCheckBox
      ? css`
          left: 0;
        `
      : null}
`;

export const ImgInputCont = styled.div`
  width: 100%;
  position: relative;

  & > div > input {
    width: 100%;
  }
`;

export const ImgLoaderCont = styled.div`
  position: absolute;
  top: 45px;
  left: 88%;
  transform: translate(-50%, 0);
`;
