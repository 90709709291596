import { PageLogo } from "infrastructure/components";

import * as S from "./Header.styled";

const Header = () => {
  return (
    <S.Header>
      <S.Header__InnerCont>
        <PageLogo /> <S.BriefTitle>Online Social Media Brief</S.BriefTitle>
      </S.Header__InnerCont>
    </S.Header>
  );
};

export default Header;
